import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../category-management/category-management.css';
import { CateogryService } from '../Service/category-service';
import Breadcrumb from '../../../layout/breadcrumb';
import { useSelector } from 'react-redux';

import AddEditPopup from '../Components/AddEditPopup';
import DeleteConfirmPopup from '../Components/DeleteConfirmPopup';
import GetTreeItemsFromData from '../Components/GetTreeItemsFromData';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';


const CategoryManagement = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [indOldName, setIndOldName] = React.useState("");
  const [indName, setIndName] = React.useState("");
  const [indType, setIndType] = React.useState("");
  const [indID, setIndID] = React.useState("");
  const [indTAID, setIndTAID] = React.useState("");
  const [indBrandID, setIndBrandID] = React.useState("");
  const [commandName, SetCommandName] = React.useState('');

  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [savemessagePopup, handlesavemessagePopup] = React.useState(false);
  const [message, setmessage] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(false);
  
  React.useEffect(() => {
    SetSessionState(selectorValue);
  }, [selectorValue]);

  let categoryService = new CateogryService();
  const addPopup = (indType, ta_id, brand_id) => {
    setIndOldName('');
    setIndName('');
    setIndType(indType);
    SetCommandName('insert')
    setIndTAID(ta_id);
    setIndBrandID(brand_id);
    setOpenModal(true);
  };
  const editPopup = (indName, indID, indType, ta_id, brand_id) => {
    setIndOldName(indName);
    setIndID(indID);
    SetCommandName('update')
    setIndName(indName);
    setIndType(indType);
    setIndTAID(ta_id);
    setIndBrandID(brand_id);
    setOpenModal(true);
  };
  const deletePopup = (indName, indID, type) => {
    setIndID(indID);
    setIndName(indName);
    SetCommandName('delete;')
    setOpenDelModal(true);
    setIndType(type);
  }

  const handleClose = () => { setOpenModal(false); };
  const deletePopupClose = () => { setOpenDelModal(false); }

  const saveItem = () => {
    let reqObj = {
      newName: indName,
      type: indType,
      id: indID,
      therapeutic_area_id: indTAID,
      category_1_id: indBrandID,
      commandName: commandName
    }
    if(indType == 'Therapeutic Area' && indName == ''){
      handlesavemessagePopup(true);
      setmessage('Therapeutic Area: Therapeutic Area Cannot be left empty')
      return;
    }
    if(indType == 'Category1' && indName == ''){
      handlesavemessagePopup(true);
      setmessage('Category 1 Name: Category 1 Name Cannot be left empty')
      return;
    }
    if(indType == 'Category2' && indName == ''){
      handlesavemessagePopup(true);
      setmessage('Category 2 Name: Category 2 Name Cannot be left empty')
      return;
    }
    let userId = 1;
    if (sessionState && sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.user && sessionState.sessionValue.payload.user.id && sessionState.country !== '') {
      userId = sessionState.sessionValue.payload.user.id;
    }
    if(commandName === 'insert') {
      reqObj.created_by = userId;
    } else {
      reqObj.updatad_by = userId;
    }
    categoryService.UpdateCategory(reqObj).then((data) => {
      GetSetAllTBIData();
      setOpenModal(false);
      setIsDisabled(false)
    }).catch((err) => {});
  }
  const deleteItem = () => {
    let reqObj = {
      newName: indName,
      type: indType,
      id: indID,
      therapeutic_area_id: indTAID,
      category_1_id: indBrandID,
      commandName: commandName
    }
    categoryService.DeleteCategory(reqObj).then((data) => {
      GetSetAllTBIData();
      setOpenDelModal(false);
      setIsDisabled(false)
    }).catch((err) => {});
  }


  const [categoryTree , SetCategoryTree] = React.useState([]);
  function GetSetAllTBIData() {
    SetCategoryTree([]);
    categoryService.GetAllCategory().then((data) => {
      let jsondata = data.data;
      if (jsondata.length > 0) {
        SetCategoryTree(jsondata);
      }
    }).catch((err) => {});
  }

  React.useEffect(() => {
    GetSetAllTBIData();
  }, []);

  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Category Management', path: '/category-management' }
  ]

  return (
    <>
    <Modal open={savemessagePopup} onClose={() =>  {{handlesavemessagePopup(false); setIsDisabled(false)}}}>
        <Grid className='paper modalstyle uservalidationPopup'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() =>  {{handlesavemessagePopup(false); setIsDisabled(false)}}}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">{message}.</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer ">
          <button type="button" onClick={() => {{handlesavemessagePopup(false); setIsDisabled(false)}}} className="modalBtn validationpopupFooter">Close</button>
          </Grid>
        </Grid>
      </Modal>
      <AddEditPopup openModal={openModal} handleClose={handleClose} indOldName={indOldName} setIndName={setIndName} indName={indName} indType={indType} saveItem={saveItem} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
      <DeleteConfirmPopup indType={indType} openDelModal={openDelModal} deletePopupClose={deletePopupClose} indName={indName} deleteItem={deleteItem} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
      <Breadcrumb setFilter={props.setFilter}  translations={props.translations} BreadcrumbData={BreadcrumbData} />
      <Grid className="main-wrp">
        <Grid container spacing={2} className='outer'>
          <Grid item xs={12} lg={12} className='header'>
            <Typography variant="h6">Category Management</Typography>
          </Grid>
          <Grid item xs={12} lg={12} className='content'>
            <GetTreeItemsFromData categoryTree={categoryTree}
              addPopup={addPopup} editPopup={editPopup} deletePopup={deletePopup} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CategoryManagement;