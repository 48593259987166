import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem'
import FileIcon from '../../../public/img/file_icon.png';
import Snackbar from '@material-ui/core/Snackbar';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ContentService } from '../Service/content-service';
import { KalturaService } from '../Service/KalturaService';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomSelect = withStyles({
    root: {
        padding: '6px',
        minHeight: '1.8rem',
        display: 'flex',
        alignItems: 'center',
        '& .placeholder': {
            color: '#A4A2A2',
            display: 'flex',
            height: '26px',
            alignItems: 'center'
        }
    }
})(Select)

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #ffffff',
        padding: 0,
        margin: 0
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #ffffff"
        },
        color: `#ffffff !important`,

    }
}));

const ContentSubtitle = (props) => {
    const [productCharactersticsModal, setProductCharactersticsModal] = useState(false);
    const [additionalCharactersticsModal, setAdditionalCharactersticsModal] = useState(false);
    const [uploadAdditionalResourcesThumbnail, setuploadAdditionalResourcesThumbnail] = useState();
    const [thumbArr, SetThumbArr] = React.useState([]);

    const [bool, SetBool] = React.useState(true);
    let contentService = new ContentService();
    const [languages, SetLanguages] = React.useState(["English", "Spanish", "Portuguese", "French", "Italian", "Korean", "Chinese", "German"]);
    const [popupState, setPopupState] = React.useState(false);
    const [popupSelectedLanguage, setPopupSelectedLanguage] = React.useState({});
    const [linkUrl, setlinkUrl] = React.useState('');
    const [linkMask, setlinkMask] = React.useState('');
    const [filename, setfilename] = React.useState('');

    const [activeEdit, setactiveEdit] = React.useState(false);
    const [activeIndex, setactiveIndex] = React.useState(0);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarType, setSnackbarType] = React.useState('');
    const [snackbarMsg, setSnackbarMsg] = React.useState('')
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const isValidUrl = urlString => {
        const urlPattern = /(?:https|http?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        return urlPattern.test(urlString);
    }

    React.useEffect(() => {
        if (props.materials.length > 0 && bool) {
            let newArr = {};
            for (let i = 0; props.materials.length > i; i++) {
                if (props.materials[i].filethumb !== "") {
                    contentService.GetS3File(props.materials[i].filethumb).then((data) => {

                        newArr['image' + props.materials[i].filethumb] = data
                        newArr = JSON.parse(JSON.stringify(newArr))
                        SetThumbArr(...thumbArr, newArr);
                    });
                }
            }
            SetBool(false);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.materials]);

    React.useEffect(() => {
    }, [thumbArr])

    const handleAdditionalResourcesImageUpload = (e) => {
        const file = e.target.files[0];
        props.setcontentlost(true)
        let key = 'Country/Banner/' + (new Date()).getTime().toString() + '-' + file.name;
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            contentService.UploadFileToS3(key, reader.result);
        }
        let materialsArr = [...props.materials];
        materialsArr.push({ filename: removeExtension(file.name), filetype: file.type, file: key, filethumb: "", disabled: true, linkUrl: "", linkMask: "" })
        props.SetMaterials(materialsArr);
        e.target.value = null;
    }

    const handleAdditionalResourcesThumbUpload = (e, index) => {
        // check if exisiting row has an image
        console.log('check if exisiting row has an image', thumbArr,  Object.keys(thumbArr)[index], Object.values(thumbArr)[index])
        const file = e.target.files[0];
        let imageByteSize = file?.size;
        if (imageByteSize > 2097152) { //2MB Limit
            props.SetS3UploadCheck(true);
            props.SetImageSizeCheck(true);
            return;
        } else {
            props.SetImageSizeCheck(false);
        }
        let imageType = file.type;
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(imageType)) {
            props.SetS3UploadCheck(true);
            props.SetImageTypeCheck(true);
            return;
        } else {
            props.SetImageTypeCheck(false);
        }
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            const newList = JSON.parse(JSON.stringify(thumbArr))
            newList[index] = fileReader.result
            SetThumbArr(newList)
        });
        fileReader.readAsDataURL(file);
        props.setcontentlost(true)
        let key = 'Country/Banner/' + (new Date()).getTime().toString() + '-' + file.name;
        let materialsArr = [...props.materials];
        materialsArr[index].filethumb = key;
        props.SetMaterials(materialsArr);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                let width = this.width;
                let height = this.height;
                if (!(width === 1280 && height === 720)) {
                    props.SetS3UploadCheck(true);
                    props.SetImageDimensionCheck(true);
                    // if previous image is uploadedis null
                    let materialsArr = [...props.materials];
                    materialsArr[index].filethumb = materialsArr[index].filethumb;
                    props.SetMaterials(materialsArr);
                    const newLists = JSON.parse(JSON.stringify(thumbArr))
                    newLists[index] = newLists[index];
                    SetThumbArr(newLists)
                    return;
                } else {
                    // let newArr = JSON.parse(JSON.stringify(thumbArr))
                    thumbArr['image' + key] = image.src
                    SetThumbArr(thumbArr);
                    contentService.UploadFileToS3(key, reader.result);
                }
            };
        }
    }
    const removeExtension = (filename) => {
        return filename.substring(0, filename.lastIndexOf('.')) || filename;
        props.setcontentlost(true)
    }
    const handleSubtitleCountry = (e, index) => {
        const newList = JSON.parse(JSON.stringify(props.subtitles))
        newList[index]['language'] = e.target.value
        props.SetSubtitles(newList)
    }

    const handleSubtitleUpload = (file, index, language) => {
        let fileValidationArray = file.name.split('.');
        if (fileValidationArray[fileValidationArray.length - 1] !== 'srt') {
            setSnackbarOpen(true);
            setSnackbarType('error');
            setSnackbarMsg('Subtitle file accepts .srt format');
            return
        }
        let key = 'Country/Banner/' + (new Date()).getTime().toString() + '-' + file.name;
        let subtitlesArr = [...props.subtitles];
        subtitlesArr[index].file = key;
        props.SetSubtitles(subtitlesArr);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            contentService.UploadFileToS3(key, reader.result);
            let model = new KalturaService();
            const uploadToken = await model.GetKalturaUploadToken();
            if (uploadToken){
                model.UploadSubtitleFile(file, uploadToken, props.kaltura_id, language);
            }
        }
        props.setcontentlost(true)
    }
    const AddMaterial = () => {
    }
    const RemoveMaterial = (index, material) => {
        let materialsArr = [...props.materials];
        let filteredArr = materialsArr.filter((item, i) => i !== index);
        let thumbs = { ...thumbArr };
        delete thumbs['image' + material.filethumb]
        SetThumbArr(thumbs);
        props.SetMaterials(filteredArr);
        props.setcontentlost(true)
    }

    const addSubtitleRow = () => {
        let filteredArr = [...props.subtitles, { language: '', file: "" }];
        props.SetSubtitles(filteredArr)
        setPopupState(false);
        setPopupSelectedLanguage({});
        props.setcontentlost(true)
    }
    const RemoveSubtitle = (index) => {
        let filteredArr = [...props.subtitles].filter((item, i) => i !== index);
        props.SetSubtitles(filteredArr);
        props.setcontentlost(true);
    }

    const getSubtitleFilename = (name) => {
        let imageString;
        const spitName = name.split('/')
        if (spitName) {
            imageString = spitName[2];
            let index = imageString.indexOf('-');
            if (index !== -1) {
                imageString = imageString.substring(index + 1);
            }
        }
        return imageString;
    }

    const GetS3FileURL = (string) => {
        contentService.GetS3FileURL(string).then((data) => {
            window.open(data, '_blank');
        });
    }

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={() => { setSnackbarOpen(false) }}>
                <Alert
                    className={`snackbar ${snackbarType}`}
                    onClose={() => { setSnackbarOpen(false) }}
                    severity={`${snackbarType}`}>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Grid className='content-field' item md={6} sm={12}>
                <FormControl component="fieldset">
                    <label className='input-label'>
                        Additional Resources
                    </label>
                </FormControl>
                <div className='add-resouces-section'>
                    <div className='table-head'>
                        <div>Name</div>
                        <div>File Thumbnail</div>
                        <div>Edit</div>
                        <div>Remove</div>
                    </div>
                    <div className='table-body'>
                        {props.materials != undefined && props.materials.length > 0 ?
                            (props.materials.map((material, rowIndex) => (
                                <div className='table-row'>
                                    <div>{
                                        material.filename ? material.filename : material.linkUrl && material.linkMask && material.linkUrl != '' ? <a target='_blank' rel="noopener noreferrer" href={material.linkUrl}>{material.linkMask}</a>
                                            : "File Name"
                                    }</div>
                                    <div className='upload-thumbnail'>

                                        {thumbArr['image' + material.filethumb] ?
                                            <div className='uploaded-image'>
                                                <img className='upload-image'
                                                    src={thumbArr['image' + material.filethumb] ? thumbArr['image' + material.filethumb] : FileIcon}
                                                    alt="prop-icon"
                                                />
                                                <label className={props.enableFields ? "upload-withfile" : 'upload-withfile disabled'} for={"file-upload" + rowIndex} >
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="upload-01">
                                                            <path id="Icon" d="M17.5 13.2998V14.2998C17.5 15.6999 17.5 16.4 17.2275 16.9348C16.9878 17.4052 16.6054 17.7876 16.135 18.0273C15.6002 18.2998 14.9001 18.2998 13.5 18.2998H6.5C5.09987 18.2998 4.3998 18.2998 3.86502 18.0273C3.39462 17.7876 3.01217 17.4052 2.77248 16.9348C2.5 16.4 2.5 15.6999 2.5 14.2998V13.2998M14.1667 7.46647L10 3.2998M10 3.2998L5.83333 7.46647M10 3.2998V13.2998" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg>
                                                </label>
                                                {props.enableFields ?
                                                    <input key={`k-${rowIndex}`} accept="image/*"
                                                        id={'file-upload' + rowIndex} className='upload-btn' type="file" onChange={(e) => {
                                                            // alert(rowIndex)
                                                            handleAdditionalResourcesThumbUpload(e, rowIndex)
                                                        }} />
                                                    : null}
                                            </div>
                                            : <div className={props.enableFields ? "upload-label" : 'upload-label disabled'}>
                                                <label for={"file-upload" + rowIndex} >
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="upload-01">
                                                            <path id="Icon" d="M17.5 13.2998V14.2998C17.5 15.6999 17.5 16.4 17.2275 16.9348C16.9878 17.4052 16.6054 17.7876 16.135 18.0273C15.6002 18.2998 14.9001 18.2998 13.5 18.2998H6.5C5.09987 18.2998 4.3998 18.2998 3.86502 18.0273C3.39462 17.7876 3.01217 17.4052 2.77248 16.9348C2.5 16.4 2.5 15.6999 2.5 14.2998V13.2998M14.1667 7.46647L10 3.2998M10 3.2998L5.83333 7.46647M10 3.2998V13.2998" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg>
                                                    Upload Thumbnail
                                                </label>
                                                <input key={`k-${rowIndex}`}
                                                    accept="image/*"
                                                    id={props.enableFields ? 'file-upload' + rowIndex : ''}
                                                    className='upload-btn' type="file" onChange={(e) => {
                                                        handleAdditionalResourcesThumbUpload(e, rowIndex)
                                                    }} />
                                                {/* <ClickAwayListener onClickAway={() => { setTooltipOpen(false) }}> */}
                                                {props.enableFields ?
                                                    <CustomTooltip
                                                        arrow
                                                        placement="bottom"
                                                        // open={tooltipOpen}
                                                        // onOpen={() => setTooltipOpen(true)}
                                                        // onClose={() => setTooltipOpen(false)}
                                                        title={
                                                            <React.Fragment>
                                                                <div>Dimension: 1280x720px</div>
                                                                <div>Format: jpg, png</div>
                                                                <div>Size: less than 2MB</div>
                                                            </React.Fragment>
                                                        }
                                                    >

                                                        <InfoOutlinedIcon onClick={() => setTooltipOpen(true)} className='infoicon' />
                                                    </CustomTooltip> : ''}
                                                {/* </ClickAwayListener> */}
                                            </div>}
                                    </div>
                                    <div>
                                        {material.linkUrl && material.linkMask && material.linkUrl != '' ?
                                            <CustomTooltip
                                                arrow
                                                placement="bottom"
                                                title={
                                                    <React.Fragment>
                                                        <div>Edit Link</div>
                                                    </React.Fragment>
                                                }
                                            >
                                                {props.enableFields ?
                                                    <EditOutlinedIcon style={{ color: '#475467' }} onClick={() => {
                                                        setlinkUrl(material.linkUrl)
                                                        setlinkMask(material.linkMask)
                                                        setactiveEdit(true)
                                                        setactiveIndex(rowIndex)
                                                        setAdditionalCharactersticsModal(true)
                                                    }} />
                                                    :
                                                    <EditOutlinedIcon style={{ color: '#99938D', cursor: 'not-allowed' }} />
                                                }
                                            </CustomTooltip> :
                                            <CustomTooltip
                                                arrow
                                                placement="bottom"
                                                title={
                                                    <React.Fragment>
                                                        <div>Edit File Name</div>
                                                    </React.Fragment>
                                                }
                                            >
                                                {props.enableFields ?
                                                    <EditOutlinedIcon style={{ color: '#475467' }} onClick={() => {
                                                        setactiveEdit(true)
                                                        setactiveIndex(rowIndex)
                                                        setfilename(material.filename)
                                                        setProductCharactersticsModal(true);
                                                    }} /> :
                                                    <EditOutlinedIcon style={{ color: '#99938D', cursor: 'not-allowed' }} />
                                                }
                                            </CustomTooltip>
                                        }</div>
                                    <div>
                                        {props.enableFields ?
                                            <RemoveCircleOutlineOutlinedIcon onClick={() => {
                                                RemoveMaterial(rowIndex, material)
                                            }} style={{ color: '#475467' }} /> :
                                            <RemoveCircleOutlineOutlinedIcon style={{ color: '#99938D', cursor: 'not-allowed' }} />
                                        }
                                    </div>
                                </div>
                            ))) : ""}
                    </div>
                    <div className='table-footer'>
                        <div className={props.enableFields ? 'more-lines' : 'more-lines disabled'} >
                            <div>
                                <label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M17.5 13.2998V14.2998C17.5 15.6999 17.5 16.4 17.2275 16.9348C16.9878 17.4052 16.6054 17.7876 16.135 18.0273C15.6002 18.2998 14.9001 18.2998 13.5 18.2998H6.5C5.09987 18.2998 4.3998 18.2998 3.86502 18.0273C3.39462 17.7876 3.01217 17.4052 2.77248 16.9348C2.5 16.4 2.5 15.6999 2.5 14.2998V13.2998M14.1667 7.46647L10 3.2998M10 3.2998L5.83333 7.46647M10 3.2998V13.2998" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Upload File
                                    {props.enableFields ?
                                        <input type="file" hidden onChange={(e) => {
                                            handleAdditionalResourcesImageUpload(e)
                                        }} /> :
                                        null}
                                </label>
                            </div>
                            <div onClick={() => {
                                if (props.enableFields) {
                                    setactiveEdit(false)
                                    setlinkUrl('')
                                    setlinkMask('')
                                    setAdditionalCharactersticsModal(true)
                                }
                            }}>
                                <label >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M17.5 8.2998L17.5 3.2998M17.5 3.2998H12.5M17.5 3.2998L10.8333 9.96647M8.33333 4.96647H6.5C5.09987 4.96647 4.3998 4.96647 3.86502 5.23895C3.39462 5.47864 3.01217 5.86109 2.77248 6.3315C2.5 6.86627 2.5 7.56634 2.5 8.96647V14.2998C2.5 15.6999 2.5 16.4 2.77248 16.9348C3.01217 17.4052 3.39462 17.7876 3.86502 18.0273C4.3998 18.2998 5.09987 18.2998 6.5 18.2998H11.8333C13.2335 18.2998 13.9335 18.2998 14.4683 18.0273C14.9387 17.7876 15.3212 17.4052 15.5608 16.9348C15.8333 16.4 15.8333 15.6999 15.8333 14.2998V12.4665" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Add External Link
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>

            <Grid className='content-field' item md={6} sm={12}>
                <FormControl component="fieldset">
                    <label className='input-label'>
                        Subtitle
                    </label>
                </FormControl>
                <div className='subtitles-section'>
                    <div className='table-head'>
                        <div>Language</div>
                        <div>Subtitle File</div>
                        <div></div>
                    </div>
                    <div className='table-body'>
                        {props.subtitles !== null || props.subtitles.length > 0 ? (
                            props.subtitles.map((subtitle, rowIndex) => (
                                <div className='table-row'>
                                    <div className='subtitle-select'>
                                        <CustomSelect
                                            variant='outlined'
                                            value={props.subtitles[rowIndex]?.language}
                                            displayEmpty
                                            disabled={!props.enableFields}
                                            onChange={(e) => {
                                                handleSubtitleCountry(e, rowIndex)
                                            }}
                                            renderValue={() => {
                                                if (!props.subtitles[rowIndex]?.language) {
                                                    return <span className='placeholder'>Select Language</span>;
                                                }
                                                return props.subtitles[rowIndex]?.language;
                                            }}
                                            inputProps={{
                                                name: 'Subtitle',
                                                id: 'vvpmsubtype-native-simple',
                                            }}
                                            IconComponent={KeyboardArrowDownIcon}
                                        >
                                            {/* <MenuItem value="" disabled> 
                                                Select Language
                                            </MenuItem>*/}

                                            {languages.map((language, index) => (
                                                <MenuItem key={index} value={language}>{language}</MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    {subtitle.file ?
                                        <div className='table-cell'>
                                            <button className='subtitle_btn' onClick={() => { GetS3FileURL(subtitle.file) }} rel="noreferrer">{subtitle.file ? getSubtitleFilename(subtitle.file) : subtitle.language}
                                            </button>
                                        </div>
                                        :
                                        <div className='table-cell'>
                                            <div className={props.enableFields ? 'upload-label' : 'upload-label disabled'}>
                                                <label for="subtitle-upload" >
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="upload-01">
                                                            <path id="Icon" d="M17.5 13.2998V14.2998C17.5 15.6999 17.5 16.4 17.2275 16.9348C16.9878 17.4052 16.6054 17.7876 16.135 18.0273C15.6002 18.2998 14.9001 18.2998 13.5 18.2998H6.5C5.09987 18.2998 4.3998 18.2998 3.86502 18.0273C3.39462 17.7876 3.01217 17.4052 2.77248 16.9348C2.5 16.4 2.5 15.6999 2.5 14.2998V13.2998M14.1667 7.46647L10 3.2998M10 3.2998L5.83333 7.46647M10 3.2998V13.2998" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                    </svg>
                                                    Upload File
                                                </label>
                                                <input id={props.enableFields ? 'subtitle-upload' : ''}
                                                    className='upload-btn' type="file" accept=".srt"
                                                    onChange={(e) => { handleSubtitleUpload(e.target.files[0], rowIndex, subtitle.language) }} />
                                            </div>
                                        </div>}
                                    <div className='table-cell'>
                                        {props.enableFields ?
                                            <RemoveCircleOutlineOutlinedIcon
                                                style={{ color: '#475467' }}
                                                onClick={() => {
                                                    RemoveSubtitle(rowIndex)
                                                }} /> :
                                            <RemoveCircleOutlineOutlinedIcon
                                                style={{ color: '#99938D', cursor: 'not-allowed' }} />
                                        }</div>
                                </div>
                            ))) : ""}

                    </div>
                    <div className='table-footer'>
                        <div className={props.enableFields ? 'more-lines' : 'more-lines disabled'}
                            onClick={() => props.enableFields && addSubtitleRow()}>
                            <label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                    <path d="M10 7.46672V14.1334M6.66667 10.8H13.3333M6.5 18.3H13.5C14.9001 18.3 15.6002 18.3 16.135 18.0276C16.6054 17.7879 16.9878 17.4054 17.2275 16.935C17.5 16.4002 17.5 15.7002 17.5 14.3V7.30005C17.5 5.89992 17.5 5.19985 17.2275 4.66507C16.9878 4.19467 16.6054 3.81222 16.135 3.57253C15.6002 3.30005 14.9001 3.30005 13.5 3.30005H6.5C5.09987 3.30005 4.3998 3.30005 3.86502 3.57253C3.39462 3.81222 3.01217 4.19467 2.77248 4.66507C2.5 5.19985 2.5 5.89992 2.5 7.30005V14.3C2.5 15.7002 2.5 16.4002 2.77248 16.935C3.01217 17.4054 3.39462 17.7879 3.86502 18.0276C4.3998 18.3 5.09987 18.3 6.5 18.3Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Add Language
                            </label>
                        </div>
                    </div>
                </div>
            </Grid>

            {/* additional characterstics modal */}
            <Dialog
                className='product-charastestics-dialog external-link'
                open={additionalCharactersticsModal}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                        },
                    },
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setAdditionalCharactersticsModal(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.0002 13C10.4297 13.5741 10.9776 14.0491 11.6067 14.3929C12.2359 14.7367 12.9317 14.9411 13.6468 14.9923C14.362 15.0435 15.0798 14.9403 15.7515 14.6897C16.4233 14.4392 17.0333 14.047 17.5402 13.54L20.5402 10.54C21.451 9.59695 21.955 8.33394 21.9436 7.02296C21.9322 5.71198 21.4063 4.45791 20.4793 3.53087C19.5523 2.60383 18.2982 2.07799 16.9872 2.0666C15.6762 2.0552 14.4132 2.55918 13.4702 3.46997L11.7502 5.17997M14.0002 11C13.5707 10.4258 13.0228 9.95078 12.3936 9.60703C11.7645 9.26327 11.0687 9.05885 10.3535 9.00763C9.63841 8.95641 8.92061 9.0596 8.24885 9.31018C7.5771 9.56077 6.96709 9.9529 6.4602 10.46L3.4602 13.46C2.54941 14.403 2.04544 15.666 2.05683 16.977C2.06822 18.288 2.59407 19.542 3.52111 20.4691C4.44815 21.3961 5.70221 21.9219 7.01319 21.9333C8.32418 21.9447 9.58719 21.4408 10.5302 20.53L12.2402 18.82" stroke="#E02F2B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='modal-heading-text'>
                        <div>{activeEdit ? 'Edit Link' : 'Add External Link'}</div>
                        <div className='modal-heading-subtext'>{activeEdit ? 'Add an external link to the content.' : 'Add an external link to the content.'}</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => setAdditionalCharactersticsModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent sx={{
                    "& .MuiDialogContent-root": {
                        padding: '0px 0px'
                    },
                }} >
                    <Grid container className="modal-body">
                        <Grid className='content-field' item md={5} sm={5}>
                            <label className='input-label '>
                                Link URL
                            </label>
                            <TextField
                                className='text-input'
                                variant="outlined"
                                placeholder="http://"
                                size="small"
                                onChange={(event) => {
                                    isValidUrl(event.target.value)
                                    setlinkUrl(event.target.value)
                                }}
                                InputLabelProps={{ shrink: false }}
                                value={linkUrl} />
                        </Grid>
                        <Grid className='content-field' item md={2} sm={2}>
                            <div className='line'></div>
                        </Grid>
                        <Grid className='content-field' item md={5} sm={5}>
                            <label className='input-label '>
                                Link Mask
                            </label>
                            <TextField
                                className='text-input'
                                variant="outlined"
                                placeholder="e.g.:Click Here!"
                                size="small"
                                onChange={(event) => {
                                    setlinkMask(event.target.value)
                                }}
                                InputLabelProps={{ shrink: false }}
                                value={linkMask} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className='modal-actions'>
                        <button className="modal-cancel" onClick={() => { setAdditionalCharactersticsModal(false) }}>Cancel</button>
                        <button className="modal-download" onClick={() => {
                            if (isValidUrl(linkUrl) && linkMask.trim() != '') {
                                if (!activeEdit) {
                                    let materialsArr = [...props.materials];
                                    materialsArr.push({ filename: "", filetype: "", file: "", filethumb: "", disabled: true, linkUrl: linkUrl, linkMask: linkMask })
                                    props.SetMaterials(materialsArr);
                                    props.setcontentlost(true)
                                    setAdditionalCharactersticsModal(false)
                                } else {
                                    let materialsArr = JSON.parse(JSON.stringify(props.materials));
                                    materialsArr[activeIndex]['linkUrl'] = linkUrl
                                    materialsArr[activeIndex]['linkMask'] = linkMask
                                    props.SetMaterials(materialsArr);
                                    props.setcontentlost(true)
                                    setAdditionalCharactersticsModal(false)
                                    setactiveEdit(false)
                                    setactiveIndex(0)
                                }
                            } else {
                                let err = '';
                                if (!isValidUrl(linkUrl)) {
                                    err += ' Invalid URL;';
                                }
                                if (linkMask.trim() == '') {
                                    err += ' Link Mask Empty;';
                                }
                                setSnackbarOpen(true);
                                setSnackbarType('error');
                                setSnackbarMsg(err);
                            }
                        }}>
                            {!activeEdit ? 'Add' : 'Update'}
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            {/* additional resources file modal */}
            <Dialog
                className='product-charastestics-dialog external-link'
                open={productCharactersticsModal}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                        },
                    },
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setProductCharactersticsModal(false)}
                aria-labelledby="alert-dialog-slide-title"
            >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.0002 13C10.4297 13.5741 10.9776 14.0491 11.6067 14.3929C12.2359 14.7367 12.9317 14.9411 13.6468 14.9923C14.362 15.0435 15.0798 14.9403 15.7515 14.6897C16.4233 14.4392 17.0333 14.047 17.5402 13.54L20.5402 10.54C21.451 9.59695 21.955 8.33394 21.9436 7.02296C21.9322 5.71198 21.4063 4.45791 20.4793 3.53087C19.5523 2.60383 18.2982 2.07799 16.9872 2.0666C15.6762 2.0552 14.4132 2.55918 13.4702 3.46997L11.7502 5.17997M14.0002 11C13.5707 10.4258 13.0228 9.95078 12.3936 9.60703C11.7645 9.26327 11.0687 9.05885 10.3535 9.00763C9.63841 8.95641 8.92061 9.0596 8.24885 9.31018C7.5771 9.56077 6.96709 9.9529 6.4602 10.46L3.4602 13.46C2.54941 14.403 2.04544 15.666 2.05683 16.977C2.06822 18.288 2.59407 19.542 3.52111 20.4691C4.44815 21.3961 5.70221 21.9219 7.01319 21.9333C8.32418 21.9447 9.58719 21.4408 10.5302 20.53L12.2402 18.82" stroke="#E02F2B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='modal-heading-text'>
                        <div>Add File</div>
                        <div className='modal-heading-subtext'>Add an file name to the content</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => setProductCharactersticsModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent sx={{
                    "& .MuiDialogContent-root": {
                        padding: '0px 0px'
                    },
                }} >
                    <Grid container className="modal-body">
                        <Grid className='content-field' item md={12} sm={12}>
                            <label className='input-label '>
                                File Name
                            </label>

                            <TextField
                                className='text-input'
                                variant="outlined"
                                placeholder="Enter Link URL"
                                size="small"
                                onChange={(event) => {
                                    setfilename(event.target.value)
                                }}
                                InputLabelProps={{ shrink: false }}
                                value={filename} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className='modal-actions'>
                        <button className="modal-cancel" onClick={() => { setProductCharactersticsModal(false) }}>Cancel</button>
                        <button className="modal-download" onClick={() => {
                            if (filename.trim() != '') {
                                if (!activeEdit) {
                                    let materialsArr = [...props.materials];
                                    materialsArr.push({ filename: "", filetype: "", file: "", filethumb: "", disabled: true, linkUrl: linkUrl, linkMask: linkMask })
                                    props.SetMaterials(materialsArr);
                                    props.setcontentlost(true)
                                    setAdditionalCharactersticsModal(false)
                                } else {
                                    let materialsArr = JSON.parse(JSON.stringify(props.materials));
                                    materialsArr[activeIndex]['filename'] = filename
                                    props.SetMaterials(materialsArr);
                                    props.setcontentlost(true)
                                    setProductCharactersticsModal(false)
                                    setactiveEdit(false)
                                    setactiveIndex(0)
                                }
                            } else {
                                let err = ' File name cannot be empty;';

                                setSnackbarOpen(true);
                                setSnackbarType('error');
                                setSnackbarMsg(err);
                            }

                        }}>
                            {!activeEdit ? 'Add' : 'Update'}
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ContentSubtitle