/* eslint-disable eqeqeq */
import React from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import EditPopup from '../Components/EditPopUp';
import editIcon from '../../../public/img/BlueEdit@3x.png';
import deleteIcon from '../../../public/img/RedClose@3x.png';
import { QuizService } from '../services/quiz-service';
import '../quiz.css';
import QuizPopupModal from '../Components/QuizPopupModal';
import { useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';


const QuizDetail = (props) => {
    const modalFunc = React.useRef(null);
    // eslint-disable-next-line no-unused-vars
    const [words,setWords] = React.useState(["A","B","C","D","E","F","G","H"]);
    const [openModal, setOpenModal] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [openDelModal, setOpenDelModal] = React.useState(false);
    const [ansVal,SetansVal] = React.useState('');
    const [oldAns,SetAns] = React.useState('');
    const [editType,SeteditType] = React.useState('');
    const [getValue,SetgetValue] = React.useState('');
    const [activeIndex,SetactiveIndex] = React.useState(0);
    const [activeSubIndex,SetactiveSubIndex] = React.useState(0);
    const [QnA,SetQnA] = React.useState([]);
    const [quizTitle,SetquizTitle] = React.useState('');
    const [quizDescription,SetquizDescription] = React.useState('');
    const [quizId,SetquizId] = React.useState('');
    const [scoreToPass,SetscoreToPass] = React.useState(0);
    const [successMessage,SetsuccessMessage] = React.useState('');
    const [failureMessage,SetfailureMessage] = React.useState('');
    const [sendDataToModal, setSendDataToModal] = React.useState("");
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
    const [ansIndex, setAnsIndex] = React.useState('');
    const [ansSubIndex, setAnsSubIndex] = React.useState('');
    const [openQueDelConfirmModal, setopenQueDelConfirmModal] = React.useState(false);
    const [queIndex, setQueIndex] = React.useState('');
    const [ansPopup, SetansPopup] = React.useState('Answer');
    const [quePopup, SetquePopup] = React.useState('Question');
    const [isDisabled,setIsDisabled] = React.useState(false)
    
    React.useEffect(() => {
        let uri = window.location.href;
        let id = uri.split('/')[uri.split('/').length - 1];
        let FinalQuestions = [
            {
                "question": "Question",
                "answers": [
                    {"reveal":"Answer","correct":true},
                    {"reveal":"Answer","correct":false},
                    {"reveal":"Answer","correct":false},
                    {"reveal":"Answer","correct":false}
                ]
            }
        ];
        if(id !== 'quiz-detail'){
            let service = new QuizService();
            let obj = {"quiz_id":id}

            service.GetAllQuiz(obj).then((data) => {
                if(data && data.data.length === 0){
                    navigate('/home')
                }
                let result = data.data[0];
                SetquizId(result.quiz_id);
                SetquizTitle(result.quiz_title);
                SetscoreToPass(result.passing_score);
                SetsuccessMessage(result.success_msg);
                SetfailureMessage(result.error_msg);
                SetQnA(result.questions);
                SetquizDescription(result.quiz_description);
            })
        }
        else {
            SetQnA(FinalQuestions);
        }
    }, []);
    

    const navigate = useNavigate();


    let quizIdFromLink = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    /**Breadcrumb for user profile */
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Quiz Management', path: '/quiz-management' },
        { value: 'Quiz Detail', path: '/quiz-management/quiz-detail/' + quizIdFromLink }
    ];

    const handleClose = () => { setOpenModal(false); };

    let model = {
        quiz_title: quizTitle,
        passing_score: scoreToPass,
        success_msg: successMessage,
        error_msg: failureMessage,
        questions: JSON.stringify(QnA),
        created_at: new Date(),
        created_by: sessionState.sessionValue.payload.user.lilly_id,
        updated_at: new Date(),
        updated_by: sessionState.sessionValue.payload.user.lilly_id,
        quiz_description: quizDescription
    }

    const isAnswerAdded = (obj) => {
        for(let i=0;i<obj.length;i++)
            if(obj[i].answers.length==0)
                return false;
        return true;
    }

    const hasIntegerNumber = (marks) => {
        return/^\d+$/.test(marks);
    }

    const handleSubmit = async () => {
        let service = new QuizService();
        if(quizId!=''){
            model.quiz_id = quizId;
            model.updated_by = sessionState.sessionValue.payload.user.lilly_id;
        }
        if(model.quiz_title.length==0){
            modalFunc.current();
            setSendDataToModal("Quiz Title Cannot be Left Empty");
        }
        else if(isNaN(Number(model.passing_score))){
            modalFunc.current();
            setSendDataToModal("Passing Score must be a number");
        }
        else if(Number(model.passing_score) < 0){
            modalFunc.current();
            setSendDataToModal("Passing Score must be positive");
        }
        else if (!hasIntegerNumber(model.passing_score)) {
            modalFunc.current();
            setSendDataToModal("Passing Score cannot be a decimal number");
        }
        else if(Number(model.passing_score) > 100){
            modalFunc.current();
            setSendDataToModal("Passing Score must be between 0-100%");
        }
        else if(model.success_msg.length==0){
            modalFunc.current();
            setSendDataToModal("Success Message should not be empty");
        }
        else if(model.error_msg.length==0){
            modalFunc.current();
            setSendDataToModal("Error Message should not be empty");
        }
        else if(JSON.parse(model.questions).length==0){
            modalFunc.current();
            setSendDataToModal("Please add at least one question");
        }
        else if(isAnswerAdded(JSON.parse(model.questions))==false){
            modalFunc.current();
            setSendDataToModal("Please add at least one answer");
        }
        else {
            service.UpdateQuiz(model).then((data) => {
                navigate('/quiz-management');
            })
        }
        setIsDisabled(true)
    }

    const handleAddQuestion = () => {
        let mainData = [...QnA];
        let sampleQuestion = {
            "question": "Question",
            "answers": [
                {"reveal":"Answer","correct":true},
                {"reveal":"Answer","correct":false},
                {"reveal":"Answer","correct":false},
                {"reveal":"Answer","correct":false}
            ]
        }
        mainData.push(sampleQuestion);
        SetQnA(mainData);
    }

    const deleteQuestion = (index) => {
        let mainData = [...QnA];
        delete mainData[index];
        mainData.splice(index,1);
        setopenQueDelConfirmModal(false);
        SetQnA(mainData);
    }

    const addNewAns = (index) => {
        let mainData = [...QnA];
        let sampleAns = {"reveal":"Answer","correct":false};
        mainData[index].answers.push(sampleAns);
        SetQnA(mainData);
    }

    React.useEffect(() => {
    }, [ansVal]);
    const editPopup = (val1) => {
        SetansVal(val1);
        setOpenModal(true);
      };
      

    const editCorrect = (index,subindex,newValue) => {
        let mainData = [...QnA];
        mainData[index].answers[subindex].correct = newValue;
        SetQnA(mainData);
    }

    const editAnswer = (index,subindex,newValue) => {
        let mainData = [...QnA];
        mainData[index].answers[subindex].reveal = newValue;
        SetQnA(mainData);
        SetansVal('');
    }

    const editQuestion = (index,newValue) => {
        let mainData = [...QnA];
        mainData[index].question = newValue;
        SetQnA(mainData);
        SetansVal('');
    }

    const deleteAnswer = (index,subindex) => {
        let mainData = [...QnA];
        delete mainData[index].answers[subindex];
        mainData[index].answers.splice(subindex,1);
        setopenDelConfirmModal(false);
        SetQnA(mainData);
    }

    const arrowUp = (index) => {
        let mainData = [...QnA];
        let up = mainData[index];
        let down = mainData[index-1];
        mainData.splice(index,1,down);
        mainData.splice(index-1,1,up);
        SetQnA(mainData);
    }

    const arrowDown = (index) => {
        let mainData = [...QnA];
        let down = mainData[index];
        let up = mainData[index+1];
        mainData.splice(index,1,up);
        mainData.splice(index+1,1,down);
        SetQnA(mainData);
    }

    React.useEffect(() => {
        if(editType==='Answer'){
            if(getValue!=undefined && getValue!='' && getValue!=null)
                editAnswer(activeIndex,activeSubIndex,getValue);
        }
        else{
            if(getValue!=undefined && getValue!='' && getValue!=null)
                editQuestion(activeIndex,getValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValue]);

    const handleAnsDelConfirmClose = () => {
        setopenDelConfirmModal(false);
    };

     const handleQueDelConfirmClose = () => {
        setopenQueDelConfirmModal(false);
    };

    const openAnsDelConfirm = (index,subindex) => {
        setAnsIndex(index);
        setAnsSubIndex(subindex)
        setopenDelConfirmModal(true);
    };

    const openQueDelConfirm = (index) => {
        setQueIndex(index);
        setopenQueDelConfirmModal(true);
    };

    return (
        <Grid>
            <Modal open={openDelConfirmModal} onClose={handleAnsDelConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleAnsDelConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">{ansPopup} will be deleted, are you sure?</Alert>
                            {/* <Alert severity="warning">click OK to continue deleting.</Alert> */}

                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleAnsDelConfirmClose()} className="modalBtn">Cancel</button>
                        <button type="button" onClick={() => deleteAnswer(ansIndex, ansSubIndex)} className="modalBtn">OK</button>
                    </Grid>
                </Grid>
            </Modal>


            <Modal open={openQueDelConfirmModal} onClose={handleQueDelConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleQueDelConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">{quePopup} will be deleted, are you sure?</Alert>
                            {/* <Alert severity="warning">click OK to continue deleting.</Alert> */}

                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleQueDelConfirmClose()} className="modalBtn">Cancel</button>
                        <button type="button" onClick={() => deleteQuestion(queIndex)} className="modalBtn">OK</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <EditPopup openModal={openModal} handleClose={handleClose} editType={editType} valueName={ansVal} SetgetValue={SetgetValue} oldAns={oldAns}/>
            <QuizPopupModal modalFunc={modalFunc} sendDataToModal={sendDataToModal} setIsDisabled={setIsDisabled}/>

            <Grid className="main-wrp">
                <Grid container spacing={2} className='outer'>
                    < Grid item xs={12} lg={12} className='header'>
                        <Typography variant="h6">Enter Your Quiz Details</Typography>
                    </Grid>

                <Grid item xs={12} lg={12} className='greyBox'>
                    <Grid container spacing={2} justifyContent="space-around">
                        <Grid item xs={12} lg={5}>
                            Quiz Title
                            <TextField fullWidth placeholder="Quiz Title{max. 80 characters}" size="small" variant="outlined" className='quizDetailsField'
                            onChange={(event)=> { SetquizTitle(event.target.value) }}
                            InputLabelProps={{ shrink: false }}
                            value={quizTitle}
                            inputProps={{ maxLength: 80 }}
                        />
                        </Grid>
                        
                        <Grid item xs={12} lg={5}>
                            Score To Pass
                            <TextField fullWidth placeholder="(0-100)%" size="small" variant="outlined" className='quizDetailsField' 
                            onChange={(event)=> { SetscoreToPass(event.target.value) }}
                            InputLabelProps={{ shrink: false }}
                            value={scoreToPass==0 ? '' : scoreToPass}
                            inputProps={{ maxLength: 3 }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="space-around">
                        <Grid item xs={12} lg={5}>
                            Quiz Description
                            <TextField fullWidth placeholder="Quiz Description{max. 200 characters}" size="small" variant="outlined" className='quizDetailsField'
                            onChange={(event)=> { SetquizDescription(event.target.value) }}
                            InputLabelProps={{ shrink: false }}
                            value={quizDescription}
                            inputProps={{ maxLength: 200 }}
                        />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            Success Message
                            <TextField fullWidth placeholder="Enter success message (max. 200 characters)" size="small" variant="outlined" className='quizDetailsField' 
                            onChange={(event)=> { SetsuccessMessage(event.target.value) }}
                            InputLabelProps={{ shrink: false }}
                            value={successMessage}
                            inputProps={{ maxLength: 200 }} 
                        />
                        </Grid>
                    </Grid>

                        <Grid container spacing={2} justifyContent="space-around">
                            <Grid item xs={12} lg={5}>
                                Failure Message
                                <TextField fullWidth placeholder="Enter error message (max. 200 characters)" size="small" variant="outlined" className='quizDetailsField'
                                    onChange={(event) => { SetfailureMessage(event.target.value) }}
                                    InputLabelProps={{ shrink: false }}
                                    value={failureMessage}
                                    inputProps={{ maxLength: 200 }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5}></Grid>
                        </Grid>
                    <Grid spacing={2} >
                    <Grid container justifyContent="flex-end" onClick={() => { handleAddQuestion(); SeteditType("Question")}} style={{cursor:"pointer"}}>
                            <Button className='addIconBtn'>
                            <img src={require('../../../public/img/Add@3x.png')} alt="Add Question" />
                            Add Question
                            </Button>
                            
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12} lg={12}>
                {QnA.map((qa,index) => {
                    return (
                        <FormGroup className='quesForm'>
                        <div className='childDrop quizsection'>
                        Q{index + 1}:    {qa?.question}
                        <div className='childDropInnr'>
                            <img className='iconChild' src={editIcon} alt='Edit' onClick={() => {SeteditType("Question");SetactiveIndex(index);editPopup(qa?.question);SetAns(qa?.question);}}/>
                        </div>
                        </div>
                        <Grid container justifyContent="flex-end">
                                    { QnA?.length==1 ?
                                        <Button disabled style={{cursor:"pointer"}}>
                                            <img src={require('../../../public/img/downArrow.png')} alt="DownArrow" />
                                        </Button>
                                        : index+1==1 ?
                                        <Button style={{cursor:"pointer"}} onClick={() => arrowDown(index)}>
                                        <img src={require('../../../public/img/downArrow.png')} alt="DownArrow" />
                                        </Button>
                                        : (index+1==QnA?.length ?
                                            <Button style={{cursor:"pointer"}} onClick={() => arrowUp(index)}>
                                                <img src={require('../../../public/img/UpArrow.png')} alt="UpArrow" />
                                            </Button>
                                        :
                                        <>
                                        <Button style={{cursor:"pointer"}} onClick={() => arrowUp(index)}>
                                            <img src={require('../../../public/img/UpArrow.png')} alt="UpArrow" />
                                        </Button>
                                        <Button style={{cursor:"pointer"}} onClick={() => arrowDown(index)}>
                                            <img src={require('../../../public/img/downArrow.png')} alt="DownArrow" />
                                        </Button>
                                        </>)
                                    }
                        </Grid>
                        {qa?.answers.map((ans,subindex) => {
                            return (
                                <>
                                    <div className='childDrop quizsection'>
                                    {words[subindex]}   <FormControlLabel control={<Checkbox checked={ans.correct} onClick={() => { editCorrect(index,subindex,!ans.correct)}}/>} label= {ans.reveal}/> 
                                        <span className='childDropInnr'>
                                            <img className='iconChild' src={editIcon} alt='Edit' onClick={() => {SeteditType("Answer");SetactiveIndex(index);SetactiveSubIndex(subindex);editPopup(ans.reveal);SetAns(ans.reveal);}}/>
                                            <img className='iconChild' src={deleteIcon} alt='Delete' onClick={() => {openAnsDelConfirm(index,subindex); SetansPopup(ans.reveal);}}/>
                                        </span>
                                    </div>
                                </>
                            )
                            })}

                            <Grid>
                            {qa?.answers?.length!=8 ?
                                <Grid container style={{cursor:"pointer"}}>
                                    <Button className='addAnswerBtn' onClick={() => addNewAns(index)}>
                                    <img src={require('../../../public/img/Add@3x.png')} alt="Add Answer" />
                                    Add Answer
                                    </Button>
                                </Grid> : null
                            }
                                <Grid container justifyContent="flex-end" style={{cursor:"pointer"}}>
                                    <Button className='deleteQue'  onClick={() => {openQueDelConfirm(index);SetquePopup(qa.question);}}>
                                    <img src={require('../../../public/img/RemoveIcon.png')} alt="Delete" />
                                    </Button>
                                </Grid>
                            </Grid>

                        </FormGroup>
                        
                    )
                })}
                </Grid>


                    <Grid container className="content grey_row">
                        <Grid container className="row_inner_box" justifyContent="space-around">
                            <Grid item lg={6} md={6} xs={6} className="text-left">
                                <Button variant="outlined" className='custom-quiz-btn createBtn' onClick={() => navigate('/quiz-management')}>Back</Button>
                            </Grid>
                            <Grid item lg={6} md={6} xs={6} className="text-right">
                                <Button variant="outlined" className='custom-quiz-btn createBtn'  disabled={isDisabled} onClick={async () => { await handleSubmit();}}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
        </Grid>
    )
}

export default QuizDetail