/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React,{ useCallback } from "react";
import { Grid } from "@material-ui/core";
import DropzoneComponent from './Dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import download from '../../../public/img/download.gif';
import uploadPng from '../../../public/img/upload_btn.png';
import AlertDialogSlide from '../Components/ContentModal';
import { KalturaService } from '../Service/KalturaService';

const UploadContent = (props) => {
  const modalFunc = React.useRef(null);
  const [loader, SetLoader] = React.useState([]);
  const [kalturaID, SetKalturaID] = React.useState('');
  const data = 'Entry id "' + kalturaID + '" not found';

  const handleDropzoneUpload = (files) => {
    if (files.length) {
      props.goToStep(1, { kalturaID: props.kalturaID, referer: 'kalturaUploadBox' })
    } else {
      modalFunc.current();
    }
  };

  const uploadHandler = useCallback(async acceptedFiles => {
    let model = new KalturaService();
    const uploadToken = await model.GetKalturaUploadToken();
    const mediaEntry = await model.GetMediaEntry();
    if (uploadToken && mediaEntry){
      model.UploadFile(acceptedFiles[0], uploadToken, mediaEntry, props.SetKalturaId, props.SetThumbnailUrl,
      props.SetUploadPercentage, props.SetDuration, props.SetShowPreviewOfVideo);
    }
    handleDropzoneUpload(acceptedFiles) 
  }, [props.SetKalturaId, props.SetThumbnailUrl,props.SetUploadPercentage, props.SetDuration, props.SetShowPreviewOfVideo]);

  const handleButtonUpload = () => {
    if (kalturaID === '') {
      modalFunc.current();
      return;
    }
    SetLoader(true);
    let kalturaService = new KalturaService();
    kalturaService.CheckValidKalturaID(kalturaID, props.SetDuration).then((data) => {
      if (data.totalCount > 0) {
        props.SetThumbnailUrl(data.objects[0].thumbnailUrl + '/width/420/height/320/quality/100');
        props.SetKalturaId(kalturaID);
        props.SetShowPreviewOfVideo(true);
        props.goToStep(1, { kalturaID: kalturaID, referer: 'kalturaIDInputBox' })
        props.setIskalturaUploaded(true);
        // props.SetUploadPercentage(100)
      } else {
        modalFunc.current();
      }
      SetLoader(false);
    });
  };

  React.useEffect(() => {
    if (props.kalturaId.length > 0) {
      props.SetKalturaID == null ? SetKalturaID(props.kalturaId) : props.SetKalturaID(props.kalturaId);
    }
  }, [props.kalturaId]);

  return (
    <Grid>
      <AlertDialogSlide modalFunc={modalFunc} sendDataToModal={data} />
      <div className="content_dropzone">
        <DropzoneComponent onDropHandle={uploadHandler} />
      </div>
      <Grid className="content_upload_section">
        <Grid item lg={12} className="content_uploadSection">
          <Grid item xs={12} sm={12} lg={12} className="content_kalturaLabel">
            Or Place the Kaltura ID of the Video or Podcast:
          </Grid>
        </Grid>
        <Grid item lg={12} className="content_uploadSection">
          <Grid item xs={12} lg={11} sm={11} className="contentUploadbtn">
            <TextField id="outlined-basic" size="small" InputLabelProps={{ shrink: false }}
              className='content_textfield' placeholder="Kaltura ID ( X_XXXXXXXX )"
              variant="outlined" value={kalturaID} onChange={(e) => { SetKalturaID(e.target.value); }} />
          </Grid>
          <Grid item xs={12} lg={1} sm={1} className="content_uploadBtnSec">
            <Button variant="outlined" onClick={handleButtonUpload} className='uploadBtn' color="secondary">
              {loader === true ? <img src={download} className='content_loader' alt="Loader" /> :
                <div className="uplaod-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M18 13.3V14.3C18 15.7002 18 16.4002 17.7275 16.935C17.4878 17.4054 17.1054 17.7879 16.635 18.0276C16.1002 18.3 15.4001 18.3 14 18.3H7C5.59987 18.3 4.8998 18.3 4.36502 18.0276C3.89462 17.7879 3.51217 17.4054 3.27248 16.935C3 16.4002 3 15.7002 3 14.3V13.3M14.6667 7.46672L10.5 3.30005M10.5 3.30005L6.33333 7.46672M10.5 3.30005V13.3" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Upload
                </div>
              }
            </Button>
          </Grid>
        </Grid>
        <Grid className="content_disclaimer">
          <Grid item xs={12} sm={12} lg={11}>
            * Kaltura ID: This application is also called InVision. You can identify the ID (alphanumeric) of the video or podcast at the very end of the URL address of the video player page. E.g., 1_ta3muqyo <br />* Note: This option will create a copy of your original video or podcast in Kaltura.
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UploadContent;
