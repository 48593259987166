import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContentService } from '../Service/content-service';
import { KalturaService } from '../Service/KalturaService';
import Modal from '@material-ui/core/Modal';
import codeCopied1 from '../../../public/img/codeCopied.png';
import Link from '@material-ui/core/Link';
import loaderNewGif from '../../../public/img/new-loader.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #ffffff',
        padding: 0,
        margin: 0
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #ffffff"
        },
        color: `#ffffff !important`,

    }
}));

const PreviewAndType = (props) => {
    let contentService = new ContentService();
    let kalturaService = new KalturaService();
    const [userData, setUserData] = React.useState([]);
    const [affiliateData, setAffiliateData] = React.useState([]);
    const [s3Show, SetS3Show] = React.useState(false);
    const [affiliateMap, SetAffiliateMap] = React.useState({});
    const [copyClicked, SetcopyClicked] = React.useState(false);
    const [localUpload, SetlocalUpload] = React.useState(false);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [thumbnailFileTitle, setThumbnailFileTitle] = React.useState('No file chosen');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarType, setSnackbarType] = React.useState('');
    const [snackbarMsg, setSnackbarMsg] = React.useState('')
    // to open the embed section
    const [openEmbedSection, setOpenEmbedSection] = React.useState(false);
    /* Revert for kaltura V7 */
    // const [playerLoadEmbed, setPlayerLoadEmbed] = React.useState(false);

    React.useEffect(() => {
        GetUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.owner, props.secondOwner]);
    React.useEffect(() => {
        let listOfAffiliates = [];
        for (let i = 0; i < props.affiliates.length; i++) {
            if (affiliateMap[props.affiliates[i]]) {
                listOfAffiliates.push(affiliateMap[props.affiliates[i]]);
            }
        }
        setAffiliateData(listOfAffiliates);
    }, [props.affiliates, affiliateMap]);
    React.useEffect(() => {
        GetUsers();
        contentService.GetAllAffiliates().then((data) => {
            let jsondata = data.data;
            if (Array.isArray(jsondata)) {
                if (jsondata.length > 0) {
                    let dict = {};
                    for (let i = 0; i < jsondata.length; i++) {
                        dict[jsondata[i].country_code] = jsondata[i];
                    }
                    SetAffiliateMap(dict);
                }
            }


        }).catch((err) => {});
    }, []);

    const GetUsers = () => {
        contentService.GetAllUsers().then((data) => {
            let jsondata = data.data;
            let arr = []
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].lilly_id != null && jsondata[i].lilly_id !== ''
                    && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
                    jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
                    jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name}(${jsondata[i].lilly_id})`
                    arr.push(jsondata[i])
                }
            }
            setUserData(arr);
            props.setSecondOwnerArray(arr)
            if (props.id !== '') {
                let ownerId = props.owner;
                let secondOwnerId = props.secondOwner;
                let ownerSecondOwnerEmailList = [];
                for (let i = 0; i < jsondata.length; i++) {
                    if (jsondata[i].id === ownerId) {
                        setOwner(jsondata[i]);
                        setOwnerName(`${jsondata[i].first_name} ${jsondata[i].last_name}`);
                        ownerSecondOwnerEmailList.push(jsondata[i].user_email)
                    }
                    if (jsondata[i].id === secondOwnerId) {
                        setSecondOwner(jsondata[i]);
                        props.setSecondOwnerVal(jsondata[i])
                        setSecondOwnerName(`${jsondata[i].first_name} ${jsondata[i].last_name}`);
                        ownerSecondOwnerEmailList.push(jsondata[i].user_email)
                    }
                }
                props.SetOwnerSecondOwnerEmail(ownerSecondOwnerEmailList);
            }
        }).catch((err) => {});
    }

    const [isUpdateVideo, SetIsUpdateVideo] = React.useState(false);
    const [embedUrl, SetEmbedUrl] = React.useState('');
    // Kaltura Player Iframe
    let ks_player_localisation = {
        'mwe-embedplayer-timed_text': 'Closed Captions',
        'mwe-timedtext-no-subtitles': 'No Subtitles',
        'mwe-embedplayer-player_fullscreen': 'Full screen',
        'mwe-embedplayer-player_closefullscreen': 'Exit full screen',
        'mwe-embedplayer-share': 'Share',
        'mwe-embedplayer-volume-mute': 'Mute',
        'mwe-embedplayer-volume-unmute': 'Unmute',
        'mwe-embedplayer-play_clip': 'Play clip',
        'mwe-embedplayer-pause_clip': 'Pause clip',
        'mwe-embedplayer-replay': 'Replay',
    };
    function InitializePlayerModel(kalturaId) {
        let widgetSetting = {
            'targetId': 'kaltura_video_player_model',
            'wid': '_1759891',
            'uiconf_id': '48704833',
            'cache_st': '1582810304',
            'entry_id': kalturaId,
            'flashvars': {
                'autoPlay': false,
                'autoMute': true,
                'strings': ks_player_localisation,
                'allowMutedAutoPlay': true,
                "share": {
                    "plug": false,
                    "plugin": false
                },
                "watermark": {
                    "plugin": true,
                    "img": window.location.origin + "/img/lillyplay_logo_watermark.png",
                    "href": "",
                    "cssClass": "bottomRight"
                },
            }
        };
        if (window.kWidget) {
            window.kWidget.embed(widgetSetting);
        }
        // Revert kaltura V7
        // let setupVars = {
        //     targetId: "kaltura_video_player_model",
        //     provider: {
        //         partnerId: 1759891,
        //         uiConfId: 53515872
        //     },
        //     playback: {
        //         pictureInPicture: false,
        //         autopause: false,
        //         autoMute: true,
        //     },
        //     plugins: {
        //         "navigation": {
        //             disable: true
        //         },
        //         "playkit-js-info": {
        //             disable: true
        //         },
        //         "floating": {
        //             disable: true
        //         },
        //         "watermark": {
        //             "plugin": true,
        //             "img": window.location.origin + "/img/lillyplay_logo_watermark.png",
        //             "href": "",
        //             "cssClass": "bottomRight"
        //         }
        //     },
        //     'width': '100%',
        //     'height': '400px',
        // }
        // let kalturaPlayer = window.KalturaPlayer.setup(setupVars);
        // setPlayerLoadEmbed(true);
        // kalturaPlayer.loadMedia({ entryId: kalturaId }).then((success) => {
        //     console.log('loadMedia', success);
        // }).catch((error) => {
        //     console.log('loadmediaerr', error);
        // });
    }

    function InitializePlayer(kalturaId) {
        let widgetSetting = {
            'targetId': 'kaltura_video_player',
            'wid': '_1759891',
            'uiconf_id': '48704833',
            'cache_st': '1582810304',
            'entry_id': kalturaId,
            'flashvars': {
                'autoPlay': false,
                'autoMute': true,
                'strings': ks_player_localisation,
                'allowMutedAutoPlay': true,
                "share": {
                    "plug": false,
                    "plugin": false
                },
            }
        };
        if (window.kWidget) {
            window.kWidget.embed(widgetSetting);
        }

    }
    React.useEffect(() => {
        let iframeUrl = "<iframe src='" + window.location.origin + "/embed.html?kalturaId=" + props.kaltura_id + "&vidId=" + props.id + "'/>"
        SetEmbedUrl(iframeUrl);
        if (props.showPreviewOfVideo && props.thumbnailUrl != '') {
            InitializePlayer(props.kaltura_id);
        }
    }, [props.kaltura_id, props.showPreviewOfVideo, props.thumbnailUrl]);

    const [ownerName, setOwnerName] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [openOwnerModal, setOpenOwnerModal] = React.useState(false);
    const [secondOwner, setSecondOwner] = React.useState('');
    const [owner, setOwner] = React.useState('');
    const [secondOwnerName, setSecondOwnerName] = React.useState('');

    const saveSecondOwner = () => {
        if (secondOwner) {
            var fullName = secondOwner.first_name + " " + secondOwner.last_name;
            setSecondOwnerName(fullName);
            setSecondOwner(secondOwner);
            props.SetSecondOwner(secondOwner.id)
        }
        setOpenModal(false);
        props.setcontentlost(true)
    }

    const saveOwner = () => {
        if (owner) {
            var fullName = owner.first_name + " " + owner.last_name;
            setOwnerName(fullName);
            props.setOwner(owner.id);
            props.Setcreated_by(owner.id)
            setOwner(owner);
        }
        setOpenOwnerModal(false);
        props.setcontentlost(true)
    }

    const handleSecondOwner = () => {
        setOpenModal(true);
    }
    const handleOwner = () => {
        setOpenOwnerModal(true);
    }
    const handleModalClose = () => {
        if (props.secondOwner === '') {
            setSecondOwner('');
        }
        setOpenModal(false);
    };
    const handleOwnerModalClose = () => {
        setOpenOwnerModal(false);
    };
    const UploadImage = (file) => {

        props.SetS3UploadCheck(false);
        props.SetImageDimensionCheck(false);
        props.SetImageSizeCheck(false);
        props.SetImageTypeCheck(false);
        props.SetS3UploadErrorCheck(false);

        SetS3Show(true);

        //Check for file size - 2 MB 
        let imageByteSize = file.size;
        if (imageByteSize > 2097152) { //2MB Limit
            setThumbnailFileTitle('No file chosen');
            props.SetS3UploadCheck(true);
            props.SetImageSizeCheck(true);
            return;
        } else {
            props.SetImageSizeCheck(false);
        }

        //Check for file type
        let imageType = file.type;
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(imageType)) {
            setThumbnailFileTitle('No file chosen');
            props.SetS3UploadCheck(true);
            props.SetImageTypeCheck(true);
            return;
        } else {
            props.SetImageTypeCheck(false);
        }

        let fileReader = new FileReader();
        let key = 'Country/Banner/' + (new Date()).getTime().toString() + '-' + file.name;
        fileReader.onload = function () {
            let kalturaImageString = this.result;
            let image = new Image();
            image.onload = function () {
                let width = this.width;
                let height = this.height;
                if (!(width === 1280 && height === 720)) {
                    setThumbnailFileTitle('No file chosen');
                    props.SetS3UploadCheck(true);
                    props.SetImageDimensionCheck(true);
                    return;
                } else {
                    if (document.getElementById("kaltura_video_player_ifp")) {
                        document.getElementById("kaltura_video_player_ifp")
                            .contentWindow.document.getElementsByClassName("playerPoster")[0].src = kalturaImageString;
                    }
                    kalturaService.UploadKalturaThumbnail(props.kaltura_id, file);

                    let reader = new FileReader();
                    reader.onload = async function () {
                        try {
                            let dataUrl = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/aws/GetPresignedUrlPutObject',
                                { key: localStorage.getItem('key'), body: this.result }, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'authorizationtoken': localStorage.getItem('jwtToken')
                                }
                            });
                            localStorage.removeItem('key');
                            return (await axios.put(dataUrl.data, this.result)).data;
                        } catch (error) {
                            let errorObj = {
                                user_id: props.created_by,
                                thumbnail_name: key,
                                log_event_type: "Content",
                                thumbnail_size: imageByteSize,
                                thumbnail_height: height,
                                thumbnail_width: width,
                                thumbnail_type: imageType,
                                record_id: props.id,
                                error_code: error.status ? error.status : '',
                                error_message: error.message
                            }
                            try {
                                await contentService.UpdateS3ImagesErrorLogs(errorObj);
                            } catch (saveerr) {  }
                            props.SetS3UploadCheck(true);
                            props.SetS3UploadErrorCheck(true);
                        }
                    };
                    localStorage.setItem('key', key);
                    reader.readAsDataURL(file);
                    contentService.LoadFile(file, file.name, key).then((keyS3) => {
                        props.SetTempThumbnail(keyS3);
                    });
                }
            }
            image.src = fileReader.result;
        }
        fileReader.readAsDataURL(file);
        props.setcontentlost(true)
    }

    const validURL = (str) => {
        try {
            new URL(str);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    React.useEffect(() => {
        const timer = setTimeout(() => {

            if (!validURL(`'${props.tempThumbnail}'`)) {
                contentService.GetS3File(props.tempThumbnail).then((data) => {
                    if (data) {
                        if (document.getElementById("kaltura_video_player_ifp")) {
                            document.getElementById("kaltura_video_player_ifp")
                                .contentWindow.document.getElementsByClassName("playerPoster")[0].src = data;
                        }
                    }
                })
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [props.tempThumbnail]);

    const [openEmbedModal, setEmbedOpenModal] = React.useState(false);
    const handleCopyCode = () => {
        navigator.clipboard.writeText(embedUrl);
    }

    const handleUpdateVideo = async (file) => {
        let fileValidationArray = file.name.split('.');
        if (fileValidationArray.includes('mp3') ||
            fileValidationArray.includes('mp4')) {
            SetlocalUpload(true);
            props.SetShowPreviewOfVideo(false)
            const uploadToken = await kalturaService.GetKalturaUploadToken();
            const mediaEntry = await kalturaService.GetMediaEntry();
            if (uploadToken && mediaEntry){
                kalturaService.UploadFile(file, uploadToken, mediaEntry, props.SetKalturaId, props.SetThumbnailUrl, props.SetUploadPercentage, props.SetDuration, props.SetShowPreviewOfVideo);
                window.kWidget.destroy("kaltura_video_player");
                SetIsUpdateVideo(true);
                props.setcontentlost(true)
            }
            return
        } else {
            setSnackbarOpen(true);
            setSnackbarType('error');
            setSnackbarMsg('Content file accepts .mp3/.mp4 format');
            return
        }

    }

    const getFilename = (name) => {
        let imageString;
        const spitName = name.split('/')
        if (spitName) {
            imageString = spitName[2];
            let index = imageString?.indexOf('-');
            if (index !== -1) {
                imageString = imageString?.substring(index + 1);
            }
        }
        return imageString;

    }

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={() => { setSnackbarOpen(false) }}>
                <Alert
                    className={`snackbar ${snackbarType}`}
                    onClose={() => { setSnackbarOpen(false) }}
                    severity={`${snackbarType}`}>
                    {snackbarMsg}
                </Alert>
            </Snackbar>

            <Modal open={openEmbedModal} onClose={() => { setEmbedOpenModal(false); SetcopyClicked(false); }} >
                <Grid className='paper modalstyle widthAuto'>
                    <Grid className="embed-modal-body">
                        <Grid container>
                            <Grid item lg={6} xs={12}>
                                <div id="kaltura_video_player_model" className="kaltura_video">
                                    <div className="loadingDiv">
                                        <img src={loaderNewGif} className='loadSvg rotate' alt='icon' />
                                        <span>Retrieving video preview...</span>
                                    </div>
                                </div>
                            </Grid>
                            {/* Revert Kaltura V7 Changes
                            <Grid item lg={8} xs={12}>
                                <div id="kaltura_video_player_model" className="kaltura_video" >
                                    {
                                        !playerLoadEmbed && (
                                            <div className="loadingDiv">
                                                <img src={loaderNewGif} className='loadSvg rotate' alt='icon' />
                                                <span>Retrieving video preview...</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </Grid> */}
                            {/* Revert Kaltura V7 Changes <Grid item lg={4} xs={12} className="brkWrd"> */}
                            <Grid item lg={6} xs={12} className="brkWrd">
                                <Grid className="embedModal">
                                    Embed Video
                                    <button className='closeModal' onClick={() => { setEmbedOpenModal(false); SetcopyClicked(false); }}></button>
                                </Grid>
                                <Grid className="modal-body posRel">
                                    {embedUrl}
                                    {copyClicked ?
                                        <Grid className="content_embed posAbs">
                                            <img className='iconChild' src={codeCopied1} alt='Edit' />
                                            Code Copied
                                        </Grid> : null}

                                </Grid>

                                <Grid className="embedModalFooter">
                                    <Link className={copyClicked ? 'codeCopyActive' : 'codeCopy'} onClick={() => { handleCopyCode(); SetcopyClicked(true); }}>Copy Code</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <Grid container className="preview-section grey_row">
                <Grid className="content" item lg={12} sm={12} md={12} xs={12}>
                    <Typography className='ds_title'>
                        Enter your Video or Podcast Details
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className="preview-section grey_row spacingissue" spacing={1}>
                <Grid item lg={6} sm={6} md={6} xs={12}>
                    <div className='thumbnail-videodetails'>
                        <div className='videos-header'>
                            {props.IskalturaUploaded && !localUpload ?
                                <>
                                    {!props.id ? <><div className='video-progress'>
                                        <span style={{ width: "100%" }}></span>
                                    </div>
                                        <div> 100% </div></> : ""}
                                </> :
                                <>
                                    {!props.id ? <> <div className='video-progress'>
                                        <span style={{ width: props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage + "%" : (!props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1) + "%" }}></span>
                                    </div>
                                        <div> {props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage : !props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1}% </div> </> :
                                        localUpload ? <>
                                            <div className='video-progress'>
                                                <span style={{ width: props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage + "%" : (!props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1) + "%" }}></span>
                                            </div>
                                            <div> {props.showPreviewOfVideo && props.uploadPercentage ? props.uploadPercentage : !props.uploadPercentage ? props.uploadPercentage : props.uploadPercentage - 1}% </div>
                                        </> : ""
                                    }
                                </>
                            }
                            <div className='video-info'>
                                Kaltura ID : <span>{props.kaltura_id}</span>
                            </div>
                        </div>
                        <div className='upload-section'>
                            <div className='video-preview'>
                                <div id="kaltura_video_player" className="kaltura_video">
                                    <div className="loadingDiv">
                                        <img src={loaderNewGif} className='loadSvg rotate' alt='icon' />
                                        <span>Retrieving video preview...</span>
                                    </div>
                                </div>
                            </div>
                            <div className={props.enableFields && props.showPreviewOfVideo ? 'upload-thumbnail' : 'upload-thumbnail disabled'}>
                                <div className='upload-thubmail-file'>
                                    <input title={thumbnailFileTitle} accept="image/*" name="thumbnail" id="thumbnail-button-file" multiple type="file" onChange={(event) => {
                                        UploadImage(event.target.files[0])
                                    }} />
                                    <label for={props.enableFields && props.showPreviewOfVideo ? 'thumbnail-button-file' : ''}>
                                        <svg className='uploadimage' width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 13V14.2C19 15.8802 19 16.7202 18.673 17.362C18.3854 17.9265 17.9265 18.3854 17.362 18.673C16.7202 19 15.8802 19 14.2 19H5.8C4.11984 19 3.27976 19 2.63803 18.673C2.07354 18.3854 1.6146 17.9265 1.32698 17.362C1 16.7202 1 15.8802 1 14.2V13M15 6L10 1M10 1L5 6M10 1V13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span className='text'>Upload Thumbnail</span>
                                        {props.enableFields ?
                                            <ClickAwayListener onClickAway={() => { setTooltipOpen(false) }}>
                                                <CustomTooltip
                                                    arrow
                                                    placement="bottom"
                                                    open={tooltipOpen}
                                                    onOpen={() => setTooltipOpen(true)}
                                                    onClose={() => setTooltipOpen(false)}
                                                    title={
                                                        <React.Fragment>
                                                            <div>Dimension: 1280x720px</div>
                                                            <div>Format: jpg, png</div>
                                                            <div>Size: less than 2MB</div>
                                                        </React.Fragment>
                                                    }
                                                >

                                                    <InfoOutlinedIcon onClick={() => setTooltipOpen(true)} className='infoicon' />
                                                </CustomTooltip>
                                            </ClickAwayListener>
                                            : ''}
                                    </label>
                                    <div className='file-details'>
                                        <span>{props.tempThumbnail ? getFilename(props.tempThumbnail) : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} sm={6} md={6} xs={12} >
                    <div className='infosection'>
                        <div className='updateandlinks'>
                            {/* <div className={props.id !== '' && props.enableFields ? 'updatevideo' : 'updatevideo disabled'}> */}
                            <label className={props.id !== '' && props.enableFields ? 'updatevideo' : 'updatevideo disabled'} for='uploadFile'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 5.1255C19.1213 6.67103 20.5 9.17456 20.5 12.0001C20.5 16.6945 16.6944 20.5001 12 20.5001H11.5M7 18.8747C4.87867 17.3292 3.5 14.8256 3.5 12.0001C3.5 7.30568 7.30558 3.5001 12 3.5001H12.5M13 22.4001L11 20.4001L13 18.4001M11 5.6001L13 3.6001L11 1.6001" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                Update Video
                            </label>
                            {props.enableFields && props.id ?
                                <input id='uploadFile' hidden type="file" accept="audio/*,video/*" onChange={(e) => handleUpdateVideo(e.target.files[0])} />
                                : null}
                            {/* </div> */}

                            {props.id !== '' && props.isRolePublisher && props.embedvideovisible ?

                                <ClickAwayListener
                                    onClickAway={() => {
                                        setOpenEmbedSection(false)
                                    }}
                                >
                                    <div className={'embedvideo'}
                                        onClick={() => setOpenEmbedSection(!openEmbedSection)}>
                                        <div className='buttonsection'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 18L22 12L16 6M8 6L2 12L8 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <span>
                                                Embed Video
                                            </span>
                                        </div>
                                        {openEmbedSection ?
                                            <ul class='dropdownsection'>
                                                {affiliateData.map((affiliate, index) => (
                                                    <li onClick={() => {
                                                        // alert(props.kaltura_id)
                                                        // Revert Kaltura V7 setPlayerLoadEmbed(false);
                                                        setEmbedOpenModal(true);
                                                        setTimeout(() => { InitializePlayerModel(props.kaltura_id); }, 2000);
                                                    }}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 18L22 12L16 6M8 6L2 12L8 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>Embed Video for {affiliate.country_name}</span></li>
                                                ))
                                                }
                                            </ul>
                                            : null}
                                    </div>
                                </ClickAwayListener>
                                : ""}

                        </div>
                        <div className='moreinfo'>
                            <div>
                                Status: - {props.id !== '' ? props.status : ''}
                            </div>
                            <div>
                                Upload Date: - {props.id !== '' ? props.recordingDate : ''}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </>
    )
}
export default PreviewAndType