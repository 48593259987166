import React from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimeRange = (props) => {
  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }
  
  const handleExpiryDate = (id, date) => {
    let obj =Object.assign([], props.timeRange);
    for(let i =0; i< props.timeRange.length; i++){
      if(obj[i].id == id){
        obj[i].exp_date = new Date(date.setUTCHours(0, 0, 0, 0));
        props.setTimeRange(obj);
        return;
      }
    }
    obj.push({id : id, exp_date : new Date(date.setUTCHours(0, 0, 0, 0))});
    props.setTimeRange(obj);
  }
  const handleStartDate = (id, date) => {
    let obj =Object.assign([], props.timeRange);
    for(let i =0; i< props.timeRange.length; i++){
      if(obj[i].id == id){
        obj[i].start_date = new Date(date.setUTCHours(0, 0, 0, 0));
        props.setTimeRange(obj);
        return;
      }
    }
    obj.push({id : id, start_date : new Date(date.setUTCHours(0, 0, 0, 0))});
    props.setTimeRange(obj);
  }

  const GetFromTime = (countryCode)=> {
    let list = Object.assign([], props.timeRange);
    for(let i =0;i<list.length;i++ ){
      if(list[i].id == countryCode){
        if(isValidDate(list[i].start_date)) {
          return list[i].start_date;
        }
        return "";
      }
    }
  }
  const GetToTime = (countryCode)=> {
    let list = Object.assign([], props.timeRange);
    for(let i =0;i<list.length;i++ ){
      if(list[i].id == countryCode){
        if(isValidDate(list[i].exp_date)) {
          return list[i].exp_date;
        }
        return "";
      }
    }
  }

  return (
    <Grid container className=" content white_row">
      <Grid item lg={12} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Restricted to time range</FormLabel>
            {props.affiliatesName?.length > 0 ? props.affiliatesName?.map((affiliate, index) => {
              return (
                <Grid>
                  <table className="timeRange">
                    <tbody>
                      <tr key={index}>
                        <td>
                          <Grid item container>
                            <Grid item lg={2} xs={3}>{affiliate.country_name}</Grid>
                            <Grid item lg={1} xs={2} className="alignCenter">From</Grid>
                            <Grid item lg={2} xs={2}>
                              <DatePicker className='timeRangeBx' selected={GetFromTime(affiliate.country_code)} onChange={(date) => { handleStartDate(affiliate.country_code, date) }}  />
                            </Grid>
                            <Grid item lg={1} xs={1} className="alignCenter">To</Grid>
                            <Grid item lg={2} xs={2}>
                              <DatePicker className='timeRangeBx' selected={GetToTime(affiliate.country_code)} onChange={(date) => { handleExpiryDate(affiliate.country_code, date) }} minDate= {GetFromTime(affiliate.country_code)} />
                            </Grid>
                          </Grid>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              )
            }) : null}
          </FormControl>
      </Grid>
    </Grid>
  )
}
export default TimeRange